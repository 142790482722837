import "./styles.scss"

import React from "react"

const MedicaDesc = ({ data }) => {
  const { desc, desc2, img, img2 } = data
  return (
    <section className="history-medica">
      <div className="container">
        <div className="history-medica__wrapper">
          <div className="row history-medica__row1">
            <div className="col-lg-6 col-12">
              <img src={img?.sourceUrl} alt={img?.altText} />
            </div>
            <div className="col-lg-6 col-12">
              <div
                className="history-medica__row1-text"
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            </div>
          </div>
          <div className="flex-column-reverse flex-lg-row history-medica__row2">
            <div className="col-lg-6 col-12">
              <div
                className="history-medica__row2-text"
                dangerouslySetInnerHTML={{ __html: desc2 }}
              />
            </div>
            <div className="col-lg-6 col-12">
              <div className="history-medica__row2-img">
                <img src={img2?.sourceUrl} alt={img2?.altText} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MedicaDesc
