import "./styles.scss"

import React from "react"

const DocDesc = ({ data }) => {
  const { desc3, img3 } = data
  return (
    <section className="history-doc">
      <div className="container">
        <div className="history-doc__wrapper">
          <div className="row">
            <div className="col-lg-6 offset-sm-3 col-sm-6 offset-lg-0 col-12">
              <img src={img3?.sourceUrl} alt={img3?.altText} />
            </div>
            <div className="col-lg-6 col-12">
              <div
                className="history-doc__text"
                dangerouslySetInnerHTML={{ __html: desc3 }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DocDesc
