import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import MedicaDesc from "../page_components/history/MedicaDesc"
import DocDesc from "../page_components/history/DocDesc"

const History = ({ data }) => {
  const breadcrumbs_data = [
    {
      name: "Historia Medica per Pedes",
      href: "/historia",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Historia",
        description:
          "Pierwsze zabiegi z zakresu pedicure leczniczego, były wykonywane jeszcze w pierwszej siedzibie firmy, jaką było Studio Urody Nefretete przy ul. Królowej Jadwigi.",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Historia Medica per Pedes" />
      <MedicaDesc data={data?.allWpPage?.nodes[0]?.acfHistory} />
      <DocDesc data={data?.allWpPage?.nodes[0]?.acfHistory} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo5Njg=" } }) {
      nodes {
        acfHistory {
          desc
          desc2
          desc3
          img {
            altText
            sourceUrl
          }
          img2 {
            altText
            sourceUrl
          }
          img3 {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`

export default History
